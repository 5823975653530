
import { defineComponent, PropType } from 'vue';
import { IVatTuPhieuNhapKho } from '@/core/interfaces/ApiResponses';
import { formatCurrencyAndQuantity } from '@/core/helpers/utils';
import DivCol from '../../../../../layout/_shared/DivCol.vue';
import useVatTu from '@/core/hooks/use-vat-tu';
import WordBreakColumn from '../../../../../components/shared/work-break-column/WordBreakColumn.vue';

export default defineComponent({
  name: 'bien-ban-vat-tu-table',

  components: { DivCol, WordBreakColumn },

  setup() {
    const { isTextDangerColumn, isValidLabel } = useVatTu();

    return { isTextDangerColumn, isValidLabel };
  },

  props: {
    items: {
      type: Array as PropType<Array<IVatTuPhieuNhapKho>>,
      default: () => ([]),
    }
  },

  methods: {
    formatCurrencyAndQuantity,

    donGiaDisplay(index) {
      return formatCurrencyAndQuantity(this.items[index].pivot.don_gia);
    },

    thanhTienDisplay(index) {
      return formatCurrencyAndQuantity(
        this.items[index].pivot.don_gia * this.items[index].pivot.so_luong,
        false,
      );
    }
  }
})
