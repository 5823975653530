
import { defineComponent, reactive, ref } from 'vue';
import { useCreatingForm } from '@/core/hooks/use-creating-form';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { HopDongService } from '@/core/services/HopDong.service';
import { IUpdatePhieuNhapTuHopDongRequest, PhieuNhapKhoService } from '@/core/services/PhieuNhapKho.service'
import { IBienBanResponseBase, IHopDongResponseBase, IPhieuNhapKhoResponseBase } from '@/core/interfaces/ApiResponses';
import { formatCurrencyAndQuantity, getErrorMsg, swalNotification } from '@/core/helpers/utils';
import { useLoading } from '@/core/hooks/use-loading';
import { useStore } from 'vuex';
import { BienBanKiemNghiemService } from '@/core/services/BienBanKiemNghiem.service';
import { useDateTime } from '@/core/hooks/use-date-time-hook';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import BienBanVatTuTable from '../bien-ban-kiem-nghiem/bien-ban-vat-tu-table/BienBanVatTuTable.vue';
import PageRowLayout from '../../../../layout/_shared/PageRowLayout.vue';
import ButtonCustom from '../../../../components/shared/buttons/ButtonCustom.vue';
import DivCol from '../../../../layout/_shared/DivCol.vue';
import DivRow from '../../../../layout/_shared/DivRow.vue';
import ToolService from '../../../../core/services/Tool.service';

export default defineComponent({
  name: 'phieu-nhap-kho-form',

  components: { PageRowLayout, ButtonCustom, BienBanVatTuTable, DivCol, DivRow },

  data() {
    return {
      idHopDong: 0,
      idBienBan: 0,
      idPhieuNhap: 0,
      totalPriceInText: 'Không',
    }
  },

  setup() {
    const { formRef, loading, isCreating } = useCreatingForm();
    const { ButtonsType, ButtonTypeColors } = useButtonCustom();
    const { goBack } = useRouterCustom();
    const { startLoading, endLoading } = useLoading();
    const { moment } = useDateTime();
    const store = useStore();
    const hopDong = ref<IHopDongResponseBase | null>(null);
    const phieuNhapData = ref<IPhieuNhapKhoResponseBase | null>(null);
    const bienDanData = ref<IBienBanResponseBase | null>(null);
    const rules = reactive({
      ngayNhapKho: [
        {
          required: true,
          message: "Phải nhập ngày",
          trigger: "blur",
        }
      ],
      nguoiGiao: [
        {
          required: true,
          message: "Tên người giao bắt buộc",
          trigger: "blur",
        }
      ],
      soHoaDon: [
        {
          required: true,
          message: "Số hóa đơn bắt buộc",
          trigger: "blur",
        }
      ],
      ngayLamHoaDon: [
        {
          required: true,
          message: "Phải nhập ngày",
          trigger: "blur",
        }
      ],
    });

    const form = reactive({
      ngayNhapKho: new Date(),
      ngayLamHoaDon: new Date(),
      nguoiGiao: '',
      soHoaDon: 0,
    });

    return {
      ButtonsType, ButtonTypeColors,
      formRef, loading, isCreating,
      goBack,
      form, rules,
      hopDong, bienDanData, phieuNhapData,
      startLoading, endLoading, moment,
      store,
    }
  },

  async mounted() {
    this.idHopDong = +this.$route.params.idHopDong;

    this.idBienBan = +this.$route.params.idBienBan;

    this.idPhieuNhap = +this.$route.params.idPhieuNhap;

    await this.fetchHopDong();

    await this.fetchPhieuNhapData();
  },

  computed: {
    totalPriceInNumber() {
      let totalInNumber = 0;
      if (!this.phieuNhapData) {
        return 0;
      } else {
        this.phieuNhapData?.vat_tu_nhap_kho.forEach(item => {
          totalInNumber += Math.round(+item.pivot.don_gia * item.pivot.so_luong)
        });

        return totalInNumber;
      }
    }
  },

  watch: {
    async totalPriceInNumber(value) {
      if (!value) {
        this.totalPriceInText = 'Không';
      } else {
        const {
          data: { data },
        } = await ToolService.convertPriceToText(value);

        this.totalPriceInText = data;
      }
    }
  },

  methods: {
    formatCurrencyAndQuantity,
    async fetchHopDong() {
      try {
        this.startLoading();
        const { data: { data } } = await HopDongService.fetchContractDetail(this.idHopDong);
        if (data) {
          this.hopDong = data
        }
        this.endLoading();
      } catch (err) {
        this.endLoading();

        await swalNotification(
					getErrorMsg(err, 'Có lỗi xảy ra khi lấy thông tin hợp đồng'),
					'error',
				);
      }
    },

    buildRequest(): IUpdatePhieuNhapTuHopDongRequest {
      return {
        id: this.idPhieuNhap,
        ma_tk_co: this.hopDong?.ma_tk ? +this.hopDong.ma_tk : 0,
        ma_ct_co: this.hopDong?.ma_ct,
        nguoi_giao: this.form.nguoiGiao,
        ngay_hoa_don: this.moment(this.form.ngayLamHoaDon.toISOString()).format('YYYY-MM-DD'),
        ngay_hop_dong: this.moment(new Date(this.hopDong.ngay_ky)).format('YYYY-MM-DD'),
        so_hoa_don: this.form.soHoaDon,
        so_hop_dong: this.hopDong.so_hop_dong,
      }
    },

    async fetchPhieuNhapData() {
      try {
        this.startLoading();

        const { data: { data: res } } = await BienBanKiemNghiemService.get(this.idBienBan);

        const {
          data: {
            data: phieuNhapResponse,
          },
        } = await PhieuNhapKhoService.get(this.idPhieuNhap);

        this.bienDanData = res;

        this.phieuNhapData = phieuNhapResponse;

        this.form.nguoiGiao = this.phieuNhapData.nguoi_giao;

        this.form.ngayNhapKho = new Date(this.phieuNhapData.created_at);

        this.form.ngayLamHoaDon = this.phieuNhapData.ngay_hoa_don ? new Date(this.phieuNhapData.ngay_hoa_don) : new Date();

        this.form.soHoaDon = this.phieuNhapData.so_hoa_don;

        this.endLoading();
      } catch(e) {
        this.endLoading();

        await swalNotification(
          getErrorMsg(e, 'Có lỗi xảy ra khi lấy thông tin phiếu nhập'),
          'error',
        );
      }
    },

    onSubmit() {
      if (!this.formRef) {
				return;
			}

      this.formRef.validate(async (valid) => {
        if (valid) {
          try {
            await this.startLoading();

            await PhieuNhapKhoService.update(
              this.buildRequest()
            );

            await this.endLoading();

            await swalNotification(
              'Chỉnh sửa phiếu nhập thành công',
            );

            await this.$router.push({
              name: 'chi-tiet-phieu-nhap-kho',
              params: {
                id: this.idPhieuNhap,
              },
            });
          } catch (e) {
            await this.endLoading();

            await swalNotification(
              getErrorMsg(e, 'Có lỗi xảy ra khi lấy thông tin hợp đồng'),
              'error',
            );
            return;
          }

          return;
        }

        return false;
      });
    }
  }
})
